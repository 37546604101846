import { useLayoutEffect, useRef, useState } from 'react'

const breakpoints: Record<string, number> = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}

function debounce(fn: (...args: any[]) => void, delay: number) {
  let timeoutId: NodeJS.Timeout
  return (...args: any[]) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn(...args), delay)
  }
}

export default function useBreakpoint(
  size: keyof typeof breakpoints,
  callback?: (isBelow: boolean) => void
) {
  const [isBelow, setIsBelow] = useState(
    typeof window !== 'undefined' ? window.innerWidth < breakpoints[size] : false
  )
  const lastWidth = useRef(typeof window !== 'undefined' ? window.innerWidth : 0)

  useLayoutEffect(() => {
    const onResize = debounce(() => {
      const currentWidth = window.innerWidth
      if (currentWidth !== lastWidth.current) {
        lastWidth.current = currentWidth
        const newIsBelow = currentWidth < breakpoints[size]
        setIsBelow(newIsBelow)
        if (callback) callback(newIsBelow)
      }
    }, 200)

    window.addEventListener('resize', onResize)
    onResize()

    return () => window.removeEventListener('resize', onResize)
  }, [size, callback])

  return isBelow
}
