import { useMatches } from '@remix-run/react'

import { type UserPreferences } from '~/server/user-preferences.server'

export default function useUserPreferences() {
  const matches = useMatches()
  let userPreferences: UserPreferences = {}

  for (const route of matches) {
    if ((route.data as any)?.$userPreferences) {
      userPreferences = (route.data as any).$userPreferences
      break
    }
  }

  const updatePreferences = (updates: UserPreferences) => {
    // update the user preferences cookie
    fetch('/api/update-preferences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updates),
    })
  }

  return [userPreferences, updatePreferences] as const
}
