import {
  IconDefinition,
  faBars,
  faBellOn,
  faChevronDown,
  faClipboardList,
  faCog,
  faMemoPad,
  faPlus,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBellOn as faBellOnSolid,
  faChevronLeft,
  faClipboardList as faClipboardListSolid,
  faCog as faCogSolid,
  faMemoPad as faMemoPadSolid,
  faTrashAlt as faTrashAltSolid,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { Link, NavLink, useParams } from '@remix-run/react'
import cn from 'classnames'
import { AnimatePresence, motion } from 'motion/react'
import React, { Fragment, createContext, useCallback, useEffect, useRef, useState } from 'react'

import { Organization } from '@rooquest/common'

import CollapsiblePanel from '~/components/CollapsiblePanel'
import { routes } from '~/config/app-routes'
import ModalAddOrganization from '~/features/organizations/components/ModalAddOrganization'
import useAppSidebar from '~/hooks/useAppSidebar'
import useBrandingImage from '~/hooks/useBrandingImage'
import useBreakpoint from '~/hooks/useBreakpoint'
import useCurrentOrganization from '~/hooks/useCurrentOrganization'
import useUserPreferences from '~/hooks/useUserPreferences'
import { type UserPreferences } from '~/server/user-preferences.server'

export const AppSidebarContext = createContext<{
  sidebarState: UserPreferences['sidebarDesktopState']
  toggleSidebar: () => void
  closeSidebar: () => void
  openSidebar: () => void
}>(null!)

export function AppSidebarProvider(props: { children: React.ReactNode }) {
  const [preferences, updatePreferences] = useUserPreferences()
  const isMobile = useBreakpoint('md')
  const [sidebarState, setSidebarState] = useState<UserPreferences['sidebarDesktopState']>(() =>
    typeof window !== 'undefined' && isMobile ? 'CLOSED' : preferences.sidebarDesktopState || 'OPEN'
  )

  const toggleSidebar = useCallback(() => {
    // update the state
    const updatedState = sidebarState === 'OPEN' ? 'CLOSED' : 'OPEN'
    setSidebarState(updatedState)
    if (!isMobile) updatePreferences({ sidebarDesktopState: updatedState })
  }, [isMobile, sidebarState, updatePreferences])

  const openSidebar = useCallback(() => {
    setSidebarState('OPEN')
    if (!isMobile) updatePreferences({ sidebarDesktopState: 'OPEN' })
  }, [isMobile, updatePreferences])

  const closeSidebar = useCallback(() => {
    setSidebarState('CLOSED')
    if (!isMobile) updatePreferences({ sidebarDesktopState: 'CLOSED' })
  }, [isMobile, updatePreferences])

  return (
    <AppSidebarContext.Provider value={{ sidebarState, openSidebar, closeSidebar, toggleSidebar }}>
      {props.children}
    </AppSidebarContext.Provider>
  )
}

export default function AppSidebar(props: { organizations: Organization[] }) {
  const params = useParams()
  const organization = useCurrentOrganization()
  const { sidebarState, toggleSidebar, closeSidebar } = useAppSidebar()

  const appLogo = useBrandingImage('senderMenuLogo')

  // admin context menu
  const adminContextMenuTriggerRef = useRef<any>(null)

  // respond to command+b to toggle the sidebar
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        event.key === 'b' &&
        !['INPUT', 'TEXTAREA'].includes(document.activeElement?.tagName || '') &&
        document.activeElement?.getAttribute('contenteditable') !== 'true'
      ) {
        event.preventDefault()
        toggleSidebar()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [toggleSidebar])

  const isMobile = useBreakpoint('md', (isBelow) => {
    if (isBelow) closeSidebar()
  })

  const config = getConfig(params.orgSlug!)

  return (
    <>
      <motion.div
        animate={{ opacity: sidebarState === 'OPEN' ? 1 : 0 }}
        initial={false}
        className={cn(
          'z-[888] md:hidden fixed inset-0 bg-black/50',
          sidebarState === 'OPEN' ? 'pointer-events-auto' : 'pointer-events-none'
        )}
        role="button"
        tabIndex={0}
        onClick={() => sidebarState === 'OPEN' && closeSidebar()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            sidebarState === 'OPEN' && closeSidebar()
          }
        }}
      />

      <motion.div
        className={cn(
          'z-[999] h-screen fixed w-[80vw] md:w-[230px] left-0 top-0 md:sticky md:block shrink-0'
        )}
        style={{
          background: `linear-gradient(135deg, ${organization.theme.gradientStart}, ${organization.theme.gradientEnd})`,
        }}
        variants={{
          MOBILE_OPEN: { x: 0, transition: { bounce: 0 } },
          MOBILE_CLOSED: { x: '-100%', transition: { bounce: 0 } },
          DESKTOP_OPEN: { width: 230, transition: { bounce: 0 } },
          DESKTOP_CLOSED: { width: 20, transition: { bounce: 0 } },
        }}
        animate={
          sidebarState === 'OPEN' ?
            isMobile ?
              'MOBILE_OPEN'
            : 'DESKTOP_OPEN'
          : isMobile ?
            'MOBILE_CLOSED'
          : 'DESKTOP_CLOSED'
        }
        initial={false}
        whileHover={
          isMobile ? {}
          : sidebarState === 'CLOSED' ?
            { width: 30 }
          : {}
        }
        onClick={() => sidebarState === 'CLOSED' && toggleSidebar()}
      >
        <div className="absolute -right-2.5 top-5 z-40">
          {/* toggle button */}
          <button
            onClick={toggleSidebar}
            className={cn(
              'text-white text-[10px] size-6 rounded-full items-center justify-center hover:scale-125 transition-transform cursor-pointer',
              sidebarState === 'OPEN' ? 'flex' : 'hidden md:flex'
            )}
            style={{ background: organization.theme.gradientEnd }}
          >
            <motion.span initial={false} animate={{ rotate: sidebarState === 'CLOSED' ? 180 : 0 }}>
              <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
            </motion.span>
          </button>
        </div>

        <motion.div
          variants={{
            OPEN: { opacity: 1, display: 'block', transition: { duration: 0.5 } },
            CLOSED: { opacity: 0, display: 'none', transition: { duration: 0 } },
          }}
          animate={sidebarState}
        >
          <Link to={config.dashboardLink}>
            <img
              alt={organization.name}
              src={appLogo}
              className="m-auto object-contain my-10 hover:scale-105 transition-transform h-[36px] max-w-[150px]"
              ref={adminContextMenuTriggerRef}
            />
          </Link>

          <OrganizationSelector
            organizations={props.organizations}
            sidebarState={sidebarState}
            toggleSidebar={toggleSidebar}
          />

          <div className="mt-6">
            {config.sections.map((section, index) => (
              <Fragment key={`sidebar-section-${index}`}>
                <SidebarSection section={section as TSidebarSection} />

                {index < config.sections.length - 1 && (
                  <div className="py-1">
                    <div className="mx-2 border-t border-black/10" />
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </motion.div>
      </motion.div>
    </>
  )
}

function OrganizationSelector(props: {
  organizations: Organization[]
  sidebarState: UserPreferences['sidebarDesktopState']
  toggleSidebar: () => void
}) {
  const params = useParams()
  const organization = useCurrentOrganization()
  const appIcon = useBrandingImage('senderMenuIcon')
  const [modalAddOrganizationOpen, setModalAddOrganizationOpen] = useState(false)
  const { closeSidebar } = useAppSidebar()

  const isMobile = useBreakpoint('md')
  const handleLinkClick = () => {
    if (isMobile) closeSidebar()
  }

  return (
    <>
      <Popover className={cn('relative', props.sidebarState === 'OPEN' ? 'px-3' : 'px-1')}>
        {({ open }) => (
          <>
            <PopoverButton
              as={motion.button}
              whileTap={{ scale: 0.98 }}
              aria-label="Select organization"
              initial={{ borderBottomRightRadius: 24, borderBottomLeftRadius: 24 }}
              animate={{
                borderBottomRightRadius: open ? 0 : 24,
                borderBottomLeftRadius: open ? 0 : 24,
              }}
              transition={{ duration: 0.15 }}
              style={{
                borderRadius: 24,
                background: 'rgba(0,0,0,0.3)',
              }}
              className="hover:opacity-90 text-white flex justify-center w-full text-left items-center h-12 px-3 py-3 shadow-xl transition-opacity outline-hidden"
            >
              {/* organization icon */}
              <span className="h-6 w-6">
                {organization.senderMenuIcon ?
                  <img src={appIcon} alt={organization.name} />
                : <div
                    className="flex items-center justify-center rounded-full uppercase"
                    style={{ background: organization.theme.primaryColor }}
                  >
                    {organization.name.slice(0, 1)}
                  </div>
                }
              </span>

              {props.sidebarState === 'OPEN' && (
                <span className="flex-1 truncate ml-3 block">{organization.name}</span>
              )}

              {/* dropdown icon */}
              {props.sidebarState === 'OPEN' && (
                <motion.span
                  animate={{ rotate: open ? 180 : 0 }}
                  className="flex items-center justify-center"
                >
                  <FontAwesomeIcon icon={faChevronDown} fixedWidth className="text-xs" />
                </motion.span>
              )}
            </PopoverButton>

            <AnimatePresence initial={false}>
              {open && (
                <PopoverPanel
                  static
                  as={motion.div}
                  initial={{ y: -10, scaleY: 0.8, height: '98%', transformOrigin: 'top' }}
                  animate={{
                    y: 0,
                    scaleY: 1,
                    height: 'fit-content',
                    transition: {
                      type: 'spring',
                      stiffness: 300,
                      damping: 20,
                      duration: 0.2,
                      bounce: 1,
                    },
                  }}
                  exit={{ scaleY: 0.8, opacity: 0, height: '98%' }}
                  className="bg-white rounded-b-[12px] max-h-fit absolute w-[calc(100%-24px)] shadow-lg overflow-hidden"
                >
                  <ul className="overflow-auto max-h-[300px]">
                    {props.organizations.map((org) => (
                      <li key={`organization-dropdown-${org.slug}`}>
                        <PopoverButton
                          as={Link}
                          to={routes.organizationDashboard(org.slug)}
                          className={cn(
                            'py-3 px-3 border-t border-gray-200 flex items-center text-app-gray-dark text-sm transition-colors',
                            params.orgSlug === org.slug ?
                              'bg-green-100 hover:bg-green-200/80'
                            : 'hover:bg-gray-100'
                          )}
                          onClick={handleLinkClick}
                        >
                          <OrganizationIcon organization={org} />
                          <span className="ml-3 flex-1">{org.name}</span>
                        </PopoverButton>
                      </li>
                    ))}
                  </ul>

                  <PopoverButton
                    onClick={() => {
                      if (isMobile) closeSidebar()
                      setModalAddOrganizationOpen(true)
                    }}
                    className="py-3 px-3.5 border-t border-gray-200 w-full flex items-center text-app-gray-dark text-sm hover:bg-gray-100 transition-colors"
                  >
                    <FontAwesomeIcon icon={faPlus} className="text-app-blue-base" fixedWidth />
                    <span className="ml-3">Add Organization</span>
                  </PopoverButton>
                </PopoverPanel>
              )}
            </AnimatePresence>
          </>
        )}
      </Popover>

      <ModalAddOrganization
        isOpen={modalAddOrganizationOpen}
        onClose={() => setModalAddOrganizationOpen(false)}
      />
    </>
  )
}

function OrganizationIcon(props: { organization: Organization }) {
  const orgIcon = useBrandingImage('senderMenuIcon', props.organization)

  return (
    <div
      className="flex items-center justify-center text-white h-6 w-6 rounded-full p-[3px]"
      style={{ background: props.organization.theme.primaryColor }}
    >
      {props.organization.senderMenuIcon ?
        <img src={orgIcon} alt={props.organization.name} />
      : <span className="uppercase">{props.organization.name.slice(0, 1)}</span>}
    </div>
  )
}

function SidebarSection(props: { section: TSidebarSection }) {
  const [userPreferences, updateUserPreferences] = useUserPreferences()
  const initialState =
    props.section.key === 'projects' ?
      userPreferences['sidebar-collapsed-sections:projects'] || 'OPEN'
    : props.section.key === 'templates' ?
      userPreferences['sidebar-collapsed-sections:templates'] || 'OPEN'
    : 'OPEN'

  const [isOpen, setIsOpen] = useState(initialState === 'OPEN')
  const toggle = (key: string) => {
    // update the user preferences
    updateUserPreferences({
      [`sidebar-collapsed-sections:${key}`]: isOpen ? 'CLOSED' : 'OPEN',
    })

    return setIsOpen(!isOpen)
  }
  const { closeSidebar } = useAppSidebar()
  const isMobile = useBreakpoint('md')
  const handleLinkClick = () => {
    if (isMobile) closeSidebar()
  }

  return (
    <div>
      {props.section.heading && (
        <div className="px-2">
          <button
            onClick={() => toggle(props.section.key)}
            className="text-white text-xs uppercase font-semibold tracking-[1px] px-2 py-1.5 rounded-lg flex items-center gap-1 hover:bg-white/10 cursor-pointer"
          >
            {props.section.heading}
            <motion.span
              initial={false}
              animate={{ rotate: isOpen ? 0 : -180 }}
              transition={{ duration: 0.2 }}
            >
              <FontAwesomeIcon fixedWidth icon={faChevronDown} />
            </motion.span>
          </button>
        </div>
      )}

      <CollapsiblePanel isOpen={isOpen}>
        <ul className="px-2">
          {props.section.links.map((link) => (
            <li key={`route-${link.route}`}>
              <NavLink
                to={link.route}
                end={link.route === routes.dashboard}
                className={({ isActive }) =>
                  cn(
                    'flex items-center gap-2 text-white text-sm hover:bg-white/10 px-3 py-2 rounded-lg',
                    isActive && 'font-semibold'
                  )
                }
                onClick={handleLinkClick}
              >
                {({ isActive }) => (
                  <>
                    <FontAwesomeIcon icon={isActive ? link.iconSolid : link.icon} fixedWidth />
                    {link.label}
                  </>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </CollapsiblePanel>
    </div>
  )
}

export function AppSidebarMobileMenuButton() {
  const { openSidebar } = useAppSidebar()

  return (
    <button
      onClick={openSidebar}
      className="p-1 md:hidden bg-gray-100 rounded-full size-8 flex items-center justify-center"
    >
      <FontAwesomeIcon icon={faBars} />
    </button>
  )
}

type Config = {
  dashboardLink: string
  sections: TSidebarSection[]
}

type TSidebarSection = {
  key: string
  heading?: string
  collapsible?: boolean
  links: { icon: IconDefinition; iconSolid: IconDefinition; label: string; route: string }[]
}

const getConfig = (orgSlug: string): Config => ({
  dashboardLink: routes.organizationDashboard(orgSlug),

  sections: [
    {
      key: 'projects',
      heading: 'Projects',
      collapsible: true,
      links: [
        {
          icon: faClipboardList,
          iconSolid: faClipboardListSolid,
          label: 'All Projects',
          route: routes.projects(orgSlug),
        },
        {
          icon: faTrashAlt,
          iconSolid: faTrashAltSolid,
          label: 'Trash',
          route: routes.organizationTrash(orgSlug),
        },
      ],
    },
    {
      key: 'templates',
      heading: 'Templates',
      collapsible: true,
      links: [
        {
          icon: faMemoPad,
          iconSolid: faMemoPadSolid,
          label: 'Task Templates',
          route: routes.taskTemplates(orgSlug),
        },
        {
          icon: faMemoPad,
          iconSolid: faMemoPadSolid,
          label: 'Project Templates',
          route: routes.projectTemplates(orgSlug),
        },
      ],
    },
    {
      key: 'activity',
      links: [
        {
          icon: faBellOn,
          iconSolid: faBellOnSolid,
          label: 'Activity',
          route: routes.organizationActivity(orgSlug),
        },
      ],
    },
    {
      key: 'settings',
      links: [
        {
          icon: faCog,
          iconSolid: faCogSolid,
          label: 'Settings',
          route: routes.organizationSettings(orgSlug),
        },
      ],
    },
  ],
})
