import { use } from 'react'

import { AppSidebarContext } from '~/components/AppSidebar'

export default function useAppSidebar() {
  const context = use(AppSidebarContext)

  if (!context) {
    throw new Error('useAppSidebar must be used within an AppSidebarProvider')
  }

  return context
}
